const locale = {
    locale: 'et_EE',
    today: 'Täna',
    now: 'Praegu',
    backToToday: 'Tagasi tänase juurde',
    ok: 'Ok',
    clear: 'Tühista',
    month: 'Kuu',
    year: 'Aasta',
    timeSelect: 'Vali aeg',
    dateSelect: 'Vali kuupäev',
    monthSelect: 'Vali kuu',
    yearSelect: 'Vali aasta',
    decadeSelect: 'Vali dekaad',
    yearFormat: 'YYYY',
    dateFormat: 'D.M.YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D.M.YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Eelmine kuu (PageUp)',
    nextMonth: 'Järgmine kuu (PageDown)',
    previousYear: 'Eelmine aasta (Control + left)',
    nextYear: 'Järgmine aasta (Control + right)',
    previousDecade: 'Eelmine dekaad',
    nextDecade: 'Järgmine dekaad',
    previousCentury: 'Eelmine sajand',
    nextCentury: 'Järgmine sajand',
};
export default locale;
