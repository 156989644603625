export default {
    // Options.jsx
    items_per_page: '/ síðu',
    jump_to: 'Síða',
    jump_to_confirm: 'staðfest',
    page: '',
    // Pagination.jsx
    prev_page: 'Fyrri síða',
    next_page: 'Næsta síða',
    prev_5: 'Til baka 5 síður',
    next_5: 'Áfram 5 síður',
    prev_3: 'Til baka 3 síður',
    next_3: 'Áfram 3 síður',
};
