export default {
    // Options.jsx
    items_per_page: '/ strana',
    jump_to: 'Přejít',
    jump_to_confirm: 'potvrdit',
    page: '',
    // Pagination.jsx
    prev_page: 'Předchozí strana',
    next_page: 'Následující strana',
    prev_5: 'Předchozích 5 stran',
    next_5: 'Následujících 5 stran',
    prev_3: 'Předchozí 3 strany',
    next_3: 'Následující 3 strany',
};
