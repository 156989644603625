export default {
    // Options.jsx
    items_per_page: '/ صفحہ',
    jump_to: 'پاس جاؤ',
    jump_to_confirm: 'تصدیق کریں',
    page: '',
    // Pagination.jsx
    prev_page: 'پچھلا صفحہ',
    next_page: 'اگلا صفحہ',
    prev_5: 'پچھلے 5 صفحات',
    next_5: 'اگلے 5 صفحات',
    prev_3: 'پچھلے 3 صفحات',
    next_3: 'اگلے 3 صفحات',
};
