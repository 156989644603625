export default {
    // Options.jsx
    items_per_page: '/ trang',
    jump_to: 'Đến',
    jump_to_confirm: 'xác nhận',
    page: '',
    // Pagination.jsx
    prev_page: 'Trang Trước',
    next_page: 'Trang Kế',
    prev_5: 'Về 5 Trang Trước',
    next_5: 'Đến 5 Trang Kế',
    prev_3: 'Về 3 Trang Trước',
    next_3: 'Đến 3 Trang Kế',
};
