export default {
    // Options.jsx
    items_per_page: '/ பக்கம்',
    jump_to: 'அடுத்த',
    jump_to_confirm: 'உறுதிப்படுத்தவும்',
    page: '',
    // Pagination.jsx
    prev_page: 'முந்தைய பக்கம்',
    next_page: 'அடுத்த பக்கம்',
    prev_5: 'முந்தைய 5 பக்கங்கள்',
    next_5: 'அடுத்த 5 பக்கங்கள்',
    prev_3: 'முந்தைய 3 பக்கங்கள்',
    next_3: 'அடுத்த 3 பக்கங்கள்',
};
