export default {
    // Options.jsx
    items_per_page: '/ psl.',
    jump_to: 'Pereiti',
    jump_to_confirm: 'patvirtinti',
    page: '',
    // Pagination.jsx
    prev_page: 'Atgal',
    next_page: 'Pirmyn',
    prev_5: 'Grįžti 5 pls.',
    next_5: 'Peršokti 5 pls.',
    prev_3: 'Grįžti 3 pls.',
    next_3: 'Peršokti 3 pls.',
};
