export default {
    // Options.jsx
    items_per_page: '/ pagina',
    jump_to: 'Ga naar',
    jump_to_confirm: 'bevestigen',
    page: '',
    // Pagination.jsx
    prev_page: 'Vorige pagina',
    next_page: 'Volgende pagina',
    prev_5: "Vorige 5 pagina's",
    next_5: "Volgende 5 pagina's",
    prev_3: "Vorige 3 pagina's",
    next_3: "Volgende 3 pagina's",
};
