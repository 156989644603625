export default {
    // Options.jsx
    items_per_page: '/ halaman',
    jump_to: 'Lompat ke',
    jump_to_confirm: 'Sahkan',
    page: '',
    // Pagination.jsx
    prev_page: 'Halaman sebelumnya',
    next_page: 'Halam seterusnya',
    prev_5: '5 halaman sebelum',
    next_5: '5 halaman seterusnya',
    prev_3: '3 halaman sebelumnya',
    next_3: '3 halaman seterusnya',
};
