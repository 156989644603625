export default {
    // Options.jsx
    items_per_page: '/ lappuse',
    jump_to: 'iet uz',
    jump_to_confirm: 'apstiprināt',
    page: '',
    // Pagination.jsx
    prev_page: 'Iepriekšējā lapa',
    next_page: 'Nākamā lapaspuse',
    prev_5: 'Iepriekšējās 5 lapas',
    next_5: 'Nākamās 5 lapas',
    prev_3: 'Iepriekšējās 3 lapas',
    next_3: 'Nākamās 3 lapas',
};
