import Pagination from '../vc-pagination/locale/hu_HU';
import DatePicker from '../date-picker/locale/hu_HU';
import TimePicker from '../time-picker/locale/hu_HU';
import Calendar from '../calendar/locale/hu_HU';
const localeValues = {
    locale: 'hu',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Szűrők',
        filterConfirm: 'Alkalmazás',
        filterReset: 'Visszaállítás',
        selectAll: 'Jelenlegi oldal kiválasztása',
        selectInvert: 'Jelenlegi oldal inverze',
        sortTitle: 'Rendezés',
    },
    Modal: {
        okText: 'Alkalmazás',
        cancelText: 'Visszavonás',
        justOkText: 'Alkalmazás',
    },
    Popconfirm: {
        okText: 'Alkalmazás',
        cancelText: 'Visszavonás',
    },
    Transfer: {
        searchPlaceholder: 'Keresés',
        itemUnit: 'elem',
        itemsUnit: 'elemek',
    },
    Upload: {
        uploading: 'Feltöltés...',
        removeFile: 'Fájl eltávolítása',
        uploadError: 'Feltöltési hiba',
        previewFile: 'Fájl előnézet',
        downloadFile: 'Fájl letöltése',
    },
    Empty: {
        description: 'Nincs adat',
    },
};
export default localeValues;
