export default {
    // Options.jsx
    items_per_page: '/ σελίδα',
    jump_to: 'Μετάβαση',
    jump_to_confirm: 'επιβεβαιώνω',
    page: '',
    // Pagination.jsx
    prev_page: 'Προηγούμενη Σελίδα',
    next_page: 'Επόμενη Σελίδα',
    prev_5: 'Προηγούμενες 5 Σελίδες',
    next_5: 'Επόμενες 5 σελίδες',
    prev_3: 'Προηγούμενες 3 Σελίδες',
    next_3: 'Επόμενες 3 Σελίδες',
};
