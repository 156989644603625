const locale = {
    locale: 'it_IT',
    today: 'Oggi',
    now: 'Adesso',
    backToToday: 'Torna ad oggi',
    ok: 'Ok',
    clear: 'Cancella',
    month: 'Mese',
    year: 'Anno',
    timeSelect: "Seleziona l'ora",
    dateSelect: 'Seleziona la data',
    monthSelect: 'Seleziona il mese',
    yearSelect: "Seleziona l'anno",
    decadeSelect: 'Seleziona il decennio',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Il mese scorso (PageUp)',
    nextMonth: 'Il prossimo mese (PageDown)',
    previousYear: "L'anno scorso (Control + sinistra)",
    nextYear: "L'anno prossimo (Control + destra)",
    previousDecade: 'Ultimo decennio',
    nextDecade: 'Prossimo decennio',
    previousCentury: 'Secolo precedente',
    nextCentury: 'Prossimo secolo',
};
export default locale;
