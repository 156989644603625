export default {
    // Options.jsx
    items_per_page: '/ página',
    jump_to: 'Ir a',
    jump_to_confirm: 'confirmar',
    page: '',
    // Pagination.jsx
    prev_page: 'Página anterior',
    next_page: 'Página siguiente',
    prev_5: '5 páginas previas',
    next_5: '5 páginas siguientes',
    prev_3: '3 páginas previas',
    next_3: '3 páginas siguientes',
};
