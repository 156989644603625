const locale = {
    locale: 'mk_MK',
    today: 'Денес',
    now: 'Сега',
    backToToday: 'Назад до денес',
    ok: 'ОК',
    clear: 'Избриши',
    month: 'Месец',
    year: 'Година',
    timeSelect: 'Избери време',
    dateSelect: 'Избери датум',
    monthSelect: 'Избери месец',
    yearSelect: 'Избери година',
    decadeSelect: 'Избери деценија',
    yearFormat: 'YYYY',
    dateFormat: 'D.M.YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D.M.YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Претходен месец (PageUp)',
    nextMonth: 'Нареден месец (PageDown)',
    previousYear: 'Претходна година (Control + left)',
    nextYear: 'Наредна година (Control + right)',
    previousDecade: 'Претходна деценија',
    nextDecade: 'Наредна деценија',
    previousCentury: 'Претходен век',
    nextCentury: 'Нареден век',
};
export default locale;
