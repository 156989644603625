const locale = {
    locale: 'az_AZ',
    today: 'Bugün',
    now: 'İndi',
    backToToday: 'Bugünə qayıt',
    ok: 'Təsdiq',
    clear: 'Təmizlə',
    month: 'Ay',
    year: 'İl',
    timeSelect: 'vaxtı seç',
    dateSelect: 'tarixi seç',
    weekSelect: 'Həftə seç',
    monthSelect: 'Ay seç',
    yearSelect: 'il seç',
    decadeSelect: 'Onillik seçin',
    yearFormat: 'YYYY',
    dateFormat: 'D.M.YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D.M.YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Əvvəlki ay (PageUp)',
    nextMonth: 'Növbəti ay (PageDown)',
    previousYear: 'Sonuncu il (Control + left)',
    nextYear: 'Növbəti il (Control + right)',
    previousDecade: 'Sonuncu onillik',
    nextDecade: 'Növbəti onillik',
    previousCentury: 'Sonuncu əsr',
    nextCentury: 'Növbəti əsr',
};
export default locale;
