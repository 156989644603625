export default {
    // Options.jsx
    items_per_page: '/ стр.',
    jump_to: 'Перейти',
    jump_to_confirm: 'подтвердить',
    page: '',
    // Pagination.jsx
    prev_page: 'Назад',
    next_page: 'Вперед',
    prev_5: 'Предыдущие 5',
    next_5: 'Следующие 5',
    prev_3: 'Предыдущие 3',
    next_3: 'Следующие 3',
};
