const locale = {
    locale: 'cs_CZ',
    today: 'Dnes',
    now: 'Nyní',
    backToToday: 'Zpět na dnešek',
    ok: 'Ok',
    clear: 'Vymazat',
    month: 'Měsíc',
    year: 'Rok',
    timeSelect: 'Vybrat čas',
    dateSelect: 'Vybrat datum',
    monthSelect: 'Vyberte měsíc',
    yearSelect: 'Vyberte rok',
    decadeSelect: 'Vyberte dekádu',
    yearFormat: 'YYYY',
    dateFormat: 'D.M.YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D.M.YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Předchozí měsíc (PageUp)',
    nextMonth: 'Následující (PageDown)',
    previousYear: 'Předchozí rok (Control + left)',
    nextYear: 'Následující rok (Control + right)',
    previousDecade: 'Předchozí dekáda',
    nextDecade: 'Následující dekáda',
    previousCentury: 'Předchozí století',
    nextCentury: 'Následující století',
};
export default locale;
