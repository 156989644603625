const locale = {
    locale: 'by_BY',
    today: 'Сёння',
    now: 'Зараз',
    backToToday: 'Дадзеная дата',
    ok: 'Ok',
    clear: 'Ачысціць',
    month: 'Месяц',
    year: 'Год',
    timeSelect: 'Выбраць час',
    dateSelect: 'Выбраць дату',
    weekSelect: 'Выбраць тыдзень',
    monthSelect: 'Выбраць месяц',
    yearSelect: 'Выбраць год',
    decadeSelect: 'Выбраць дзесяцігоддзе',
    yearFormat: 'YYYY',
    dateFormat: 'D-M-YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D-M-YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Папярэдні месяц (PageUp)',
    nextMonth: 'Наступны месяц (PageDown)',
    previousYear: 'Папярэдні год (Control + left)',
    nextYear: 'Наступны год (Control + right)',
    previousDecade: 'Папярэдняе дзесяцігоддзе',
    nextDecade: 'Наступнае дзесяцігоддзе',
    previousCentury: 'Папярэдні век',
    nextCentury: 'Наступны век',
};
export default locale;
