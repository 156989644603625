const locale = {
    locale: 'fa_IR',
    today: 'امروز',
    now: 'اکنون',
    backToToday: 'بازگشت به روز',
    ok: 'باشه',
    clear: 'پاک کردن',
    month: 'ماه',
    year: 'سال',
    timeSelect: 'انتخاب زمان',
    dateSelect: 'انتخاب تاریخ',
    monthSelect: 'یک ماه را انتخاب کنید',
    yearSelect: 'یک سال را انتخاب کنید',
    decadeSelect: 'یک دهه را انتخاب کنید',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'ماه قبل (PageUp)',
    nextMonth: 'ماه بعد (PageDown)',
    previousYear: 'سال قبل (Control + left)',
    nextYear: 'سال بعد (Control + right)',
    previousDecade: 'دهه قبل',
    nextDecade: 'دهه بعد',
    previousCentury: 'قرن قبل',
    nextCentury: 'قرن بعد',
};
export default locale;
