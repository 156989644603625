const locale = {
    locale: 'nl_BE',
    today: 'Vandaag',
    now: 'Nu',
    backToToday: 'Terug naar vandaag',
    ok: 'Ok',
    clear: 'Reset',
    month: 'Maand',
    year: 'Jaar',
    timeSelect: 'Selecteer tijd',
    dateSelect: 'Selecteer datum',
    monthSelect: 'Kies een maand',
    yearSelect: 'Kies een jaar',
    decadeSelect: 'Kies een decennium',
    yearFormat: 'YYYY',
    dateFormat: 'D-M-YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D-M-YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Vorige maand (PageUp)',
    nextMonth: 'Volgende maand (PageDown)',
    previousYear: 'Vorig jaar (Control + left)',
    nextYear: 'Volgend jaar (Control + right)',
    previousDecade: 'Vorig decennium',
    nextDecade: 'Volgend decennium',
    previousCentury: 'Vorige eeuw',
    nextCentury: 'Volgende eeuw',
};
export default locale;
