export default {
    // Options.jsx
    items_per_page: '/ стр',
    jump_to: 'Оди на',
    jump_to_confirm: 'потврди',
    page: '',
    // Pagination.jsx
    prev_page: 'Претходна страница',
    next_page: 'Наредна страница',
    prev_5: 'Претходни 5 страници',
    next_5: 'Наредни 5 страници',
    prev_3: 'Претходни 3 страници',
    next_3: 'Наредни 3 страници',
};
