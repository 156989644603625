export default {
    // Options.jsx
    items_per_page: '/ oldal',
    jump_to: 'Ugrás',
    jump_to_confirm: 'megerősít',
    page: '',
    // Pagination.jsx
    prev_page: 'Előző oldal',
    next_page: 'Következő oldal',
    prev_5: 'Előző 5 oldal',
    next_5: 'Következő 5 oldal',
    prev_3: 'Előző 3 oldal',
    next_3: 'Következő 3 oldal', // 'Next 3 Pages',
};
