export default {
    // Options.jsx
    items_per_page: '/ Seite',
    jump_to: 'Gehe zu',
    jump_to_confirm: 'bestätigen',
    page: '',
    // Pagination.jsx
    prev_page: 'Vorherige Seite',
    next_page: 'Nächste Seite',
    prev_5: '5 Seiten zurück',
    next_5: '5 Seiten vor',
    prev_3: '3 Seiten zurück',
    next_3: '3 Seiten vor',
};
