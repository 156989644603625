export default {
    // Options.jsx
    items_per_page: '/ halaman',
    jump_to: 'Menuju',
    jump_to_confirm: 'konfirmasi',
    page: '',
    // Pagination.jsx
    prev_page: 'Halaman Sebelumnya',
    next_page: 'Halaman Berikutnya',
    prev_5: '5 Halaman Sebelumnya',
    next_5: '5 Halaman Berikutnya',
    prev_3: '3 Halaman Sebelumnya',
    next_3: '3 Halaman Berikutnya',
};
