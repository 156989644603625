const locale = {
    locale: 'el_GR',
    today: 'Σήμερα',
    now: 'Τώρα',
    backToToday: 'Πίσω στη σημερινή μέρα',
    ok: 'Ok',
    clear: 'Καθαρισμός',
    month: 'Μήνας',
    year: 'Έτος',
    timeSelect: 'Επιλογή ώρας',
    dateSelect: 'Επιλογή ημερομηνίας',
    monthSelect: 'Επιλογή μήνα',
    yearSelect: 'Επιλογή έτους',
    decadeSelect: 'Επιλογή δεκαετίας',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Προηγούμενος μήνας (PageUp)',
    nextMonth: 'Επόμενος μήνας (PageDown)',
    previousYear: 'Προηγούμενο έτος (Control + αριστερά)',
    nextYear: 'Επόμενο έτος (Control + δεξιά)',
    previousDecade: 'Προηγούμενη δεκαετία',
    nextDecade: 'Επόμενη δεκαετία',
    previousCentury: 'Προηγούμενος αιώνας',
    nextCentury: 'Επόμενος αιώνας',
};
export default locale;
