export default {
    // Options.jsx
    items_per_page: '/ pàgina',
    jump_to: 'Anar a',
    jump_to_confirm: 'Confirma',
    page: '',
    // Pagination.jsx
    prev_page: 'Pàgina prèvia',
    next_page: 'Pàgina següent',
    prev_5: '5 pàgines prèvies',
    next_5: '5 pàgines següents',
    prev_3: '3 pàgines prèvies',
    next_3: '3 pàgines següents',
};
