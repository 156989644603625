import Pagination from '../vc-pagination/locale/kmr_IQ';
import DatePicker from '../date-picker/locale/kmr_IQ';
import TimePicker from '../time-picker/locale/kmr_IQ';
import Calendar from '../calendar/locale/kmr_IQ';
// please use antd/lib/locale/kmr_IQ instead
// keep this file for compatibility
// https://github.com/ant-design/ant-design/issues/25778
const localeValues = {
    locale: 'ku-iq',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Menuê peldanka',
        filterConfirm: 'Temam',
        filterReset: 'Jê bibe',
        selectAll: 'Hemî hilbijêre',
        selectInvert: 'Hilbijartinan veguhere',
    },
    Modal: {
        okText: 'Temam',
        cancelText: 'Betal ke',
        justOkText: 'Temam',
    },
    Popconfirm: {
        okText: 'Temam',
        cancelText: 'Betal ke',
    },
    Transfer: {
        searchPlaceholder: 'Lêgerîn',
        itemUnit: 'tişt',
        itemsUnit: 'tişt',
    },
    Upload: {
        uploading: 'Bardike...',
        removeFile: 'Pelê rabike',
        uploadError: 'Xeta barkirine',
        previewFile: 'Pelê pêşbibîne',
        downloadFile: 'Pelê dakêşin',
    },
    Empty: {
        description: 'Agahî tune',
    },
};
export default localeValues;
