export default {
    // Options.jsx
    items_per_page: '/ صفحه',
    jump_to: 'برو به',
    jump_to_confirm: 'تایید',
    page: '',
    // Pagination.jsx
    prev_page: 'صفحه قبلی',
    next_page: 'صفحه بعدی',
    prev_5: '۵ صفحه قبلی',
    next_5: '۵ صفحه بعدی',
    prev_3: '۳ صفحه قبلی',
    next_3: '۳ صفحه بعدی',
};
