export default {
    // Options.jsx
    items_per_page: '/ पृष्ठ',
    jump_to: 'इस पर चलें',
    jump_to_confirm: 'पुष्टि करें',
    page: '',
    // Pagination.jsx
    prev_page: 'पिछला पृष्ठ',
    next_page: 'अगला पृष्ठ',
    prev_5: 'पिछले 5 पृष्ठ',
    next_5: 'अगले 5 पृष्ठ',
    prev_3: 'पिछले 3 पृष्ठ',
    next_3: 'अगले 3 पेज',
};
