const locale = {
    locale: 'ga_IE',
    today: 'inniu',
    now: 'anois',
    backToToday: 'Ar ais inniu',
    ok: 'ceart go leor',
    clear: 'soiléir',
    month: 'mhí',
    year: 'bhliain',
    timeSelect: 'roghnaigh am',
    dateSelect: 'roghnaigh dáta',
    weekSelect: 'Roghnaigh seachtain',
    monthSelect: 'Roghnaigh mí',
    yearSelect: 'Roghnaigh bliain',
    decadeSelect: 'Roghnaigh deich mbliana',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'An mhí roimhe seo (PageUp)',
    nextMonth: 'An mhí seo chugainn (PageDown)',
    previousYear: 'Anuraidh (Control + left)',
    nextYear: 'An bhliain seo chugainn (Control + right)',
    previousDecade: 'Le deich mbliana anuas',
    nextDecade: 'Deich mbliana amach romhainn',
    previousCentury: 'An chéid seo caite',
    nextCentury: 'An chéad aois eile',
};
export default locale;
