export default {
    // Options.jsx
    items_per_page: '/ хуудас',
    jump_to: 'Шилжих',
    jump_to_confirm: 'сонгох',
    page: '',
    // Pagination.jsx
    prev_page: 'Өмнөх хуудас',
    next_page: 'Дараагийн хуудас',
    prev_5: 'Дараагийн 5 хуудас',
    next_5: 'Дараагийн 5 хуудас',
    prev_3: 'Дараагийн 3 хуудас',
    next_3: 'Дараагийн 3 хуудас',
};
