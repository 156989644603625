export default {
    // Options.jsx
    items_per_page: '/ പേജ്',
    jump_to: 'അടുത്തത്',
    jump_to_confirm: 'ഉറപ്പാക്കുക',
    page: '',
    // Pagination.jsx
    prev_page: 'മുൻപുള്ള പേജ്',
    next_page: 'അടുത്ത പേജ്',
    prev_5: 'മുൻപുള്ള 5 പേജുകൾ',
    next_5: 'അടുത്ത 5 പേജുകൾ',
    prev_3: 'മുൻപുള്ള 3 പേജുകൾ',
    next_3: 'അടുത്ത 3 പേജുകൾ',
};
