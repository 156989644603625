const locale = {
    locale: 'hr_HR',
    today: 'Danas',
    now: 'Sad',
    backToToday: 'Natrag na danas',
    ok: 'Ok',
    clear: 'Očisti',
    month: 'Mjesec',
    year: 'Godina',
    timeSelect: 'odaberite vrijeme',
    dateSelect: 'odaberite datum',
    weekSelect: 'Odaberite tjedan',
    monthSelect: 'Odaberite mjesec',
    yearSelect: 'Odaberite godinu',
    decadeSelect: 'Odaberite desetljeće',
    yearFormat: 'YYYY',
    dateFormat: 'D.M.YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D.M.YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Prošli mjesec (PageUp)',
    nextMonth: 'Sljedeći mjesec (PageDown)',
    previousYear: 'Prošla godina (Control + left)',
    nextYear: 'Sljedeća godina (Control + right)',
    previousDecade: 'Prošlo desetljeće',
    nextDecade: 'Sljedeće desetljeće',
    previousCentury: 'Prošlo stoljeće',
    nextCentury: 'Sljedeće stoljeće',
};
export default locale;
