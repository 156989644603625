const locale = {
    locale: 'lt_LT',
    today: 'Šiandien',
    now: 'Dabar',
    backToToday: 'Rodyti šiandien',
    ok: 'Gerai',
    clear: 'Išvalyti',
    month: 'Mėnesis',
    year: 'Metai',
    timeSelect: 'Pasirinkti laiką',
    dateSelect: 'Pasirinkti datą',
    monthSelect: 'Pasirinkti mėnesį',
    yearSelect: 'Pasirinkti metus',
    decadeSelect: 'Pasirinkti dešimtmetį',
    yearFormat: 'YYYY',
    dateFormat: 'YYYY-MM-DD',
    dayFormat: 'DD',
    dateTimeFormat: 'YYYY-MM-DD HH:MM:SS',
    monthBeforeYear: true,
    previousMonth: 'Buvęs mėnesis (PageUp)',
    nextMonth: 'Sekantis mėnesis (PageDown)',
    previousYear: 'Buvę metai (Control + left)',
    nextYear: 'Sekantis metai (Control + right)',
    previousDecade: 'Buvęs dešimtmetis',
    nextDecade: 'Sekantis dešimtmetis',
    previousCentury: 'Buvęs amžius',
    nextCentury: 'Sekantis amžius',
};
export default locale;
