const locale = {
    locale: 'hu_HU',
    today: 'Ma',
    now: 'Most',
    backToToday: 'Vissza a mai napra',
    ok: 'Ok',
    clear: 'Törlés',
    month: 'Hónap',
    year: 'Év',
    timeSelect: 'Időpont kiválasztása',
    dateSelect: 'Dátum kiválasztása',
    monthSelect: 'Hónap kiválasztása',
    yearSelect: 'Év kiválasztása',
    decadeSelect: 'Évtized kiválasztása',
    yearFormat: 'YYYY',
    dateFormat: 'YYYY/MM/DD',
    dayFormat: 'DD',
    dateTimeFormat: 'YYYY/MM/DD HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Előző hónap (PageUp)',
    nextMonth: 'Következő hónap (PageDown)',
    previousYear: 'Múlt év (Control + left)',
    nextYear: 'Jövő év (Control + right)',
    previousDecade: 'Előző évtized',
    nextDecade: 'Következő évtized',
    previousCentury: 'Múlt évszázad',
    nextCentury: 'Jövő évszázad', // 'Next century',
};
export default locale;
