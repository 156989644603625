export default {
    // Options.jsx
    items_per_page: '/ leathanach',
    jump_to: 'Téigh',
    jump_to_confirm: 'dheimhnigh',
    page: '',
    // Pagination.jsx
    prev_page: 'Leathanach Roimhe Seo',
    next_page: 'An chéad leathanach eile',
    prev_5: '5 leathanach roimhe seo',
    next_5: 'Ar Aghaidh 5 Leathanaigh',
    prev_3: '3 leathanach roimhe seo',
    next_3: 'Ar Aghaidh 3 Leathanaigh',
};
