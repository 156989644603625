export default {
    // Options.jsx
    items_per_page: '/ sida',
    jump_to: 'Gå till',
    jump_to_confirm: 'bekräfta',
    page: '',
    // Pagination.jsx
    prev_page: 'Föreg sida',
    next_page: 'Nästa sida',
    prev_5: 'Föreg 5 sidor',
    next_5: 'Nästa 5 sidor',
    prev_3: 'Föreg 3 sidor',
    next_3: 'Nästa 3 sidor',
};
