export default {
    // Options.jsx
    items_per_page: '/ หน้า',
    jump_to: 'ไปยัง',
    jump_to_confirm: 'ยืนยัน',
    page: '',
    // Pagination.jsx
    prev_page: 'หน้าก่อนหน้า',
    next_page: 'หน้าถัดไป',
    prev_5: 'ย้อนกลับ 5 หน้า',
    next_5: 'ถัดไป 5 หน้า',
    prev_3: 'ย้อนกลับ 3 หน้า',
    next_3: 'ถัดไป 3 หน้า',
};
