export default {
    // Options.jsx
    items_per_page: '/ сторінці',
    jump_to: 'Перейти',
    jump_to_confirm: 'підтвердити',
    page: '',
    // Pagination.jsx
    prev_page: 'Попередня сторінка',
    next_page: 'Наступна сторінка',
    prev_5: 'Попередні 5 сторінок',
    next_5: 'Наступні 5 сторінок',
    prev_3: 'Попередні 3 сторінки',
    next_3: 'Наступні 3 сторінки',
};
