const locale = {
    locale: 'es_ES',
    today: 'Hoy',
    now: 'Ahora',
    backToToday: 'Volver a hoy',
    ok: 'Aceptar',
    clear: 'Limpiar',
    month: 'Mes',
    year: 'Año',
    timeSelect: 'Seleccionar hora',
    dateSelect: 'Seleccionar fecha',
    monthSelect: 'Elegir un mes',
    yearSelect: 'Elegir un año',
    decadeSelect: 'Elegir una década',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Mes anterior (PageUp)',
    nextMonth: 'Mes siguiente (PageDown)',
    previousYear: 'Año anterior (Control + left)',
    nextYear: 'Año siguiente (Control + right)',
    previousDecade: 'Década anterior',
    nextDecade: 'Década siguiente',
    previousCentury: 'Siglo anterior',
    nextCentury: 'Siglo siguiente',
};
export default locale;
