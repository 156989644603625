const locale = {
    locale: 'ka_GE',
    today: 'დღეს',
    now: 'ახლა',
    backToToday: 'მიმდინარე თარიღი',
    ok: 'Ok',
    clear: 'გასუფთავება',
    month: 'თვე',
    year: 'წელი',
    timeSelect: 'დროის არჩევა',
    dateSelect: 'თარიღის არჩევა',
    weekSelect: 'კვირის არჩევა',
    monthSelect: 'თვის არჩევა',
    yearSelect: 'წლის არჩევა',
    decadeSelect: 'ათწლეულის არჩევა',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'წინა თვე (PageUp)',
    nextMonth: 'მომდევნო თვე (PageDown)',
    previousYear: 'წინა წელი (Control + left)',
    nextYear: 'მომდევნო წელი (Control + right)',
    previousDecade: 'წინა ათწლეული',
    nextDecade: 'მომდევნო ათწლეული',
    previousCentury: 'გასული საუკუნე',
    nextCentury: 'მომდევნო საუკუნე',
};
export default locale;
