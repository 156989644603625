const locale = {
    locale: 'sv_SE',
    today: 'I dag',
    now: 'Nu',
    backToToday: 'Till idag',
    ok: 'Ok',
    clear: 'Avbryt',
    month: 'Månad',
    year: 'År',
    timeSelect: 'Välj tidpunkt',
    dateSelect: 'Välj datum',
    monthSelect: 'Välj månad',
    yearSelect: 'Välj år',
    decadeSelect: 'Välj årtionde',
    yearFormat: 'YYYY',
    dateFormat: 'YYYY-MM-DD',
    dayFormat: 'D',
    dateTimeFormat: 'YYYY-MM-DD H:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Förra månaden (PageUp)',
    nextMonth: 'Nästa månad (PageDown)',
    previousYear: 'Föreg år (Control + left)',
    nextYear: 'Nästa år (Control + right)',
    previousDecade: 'Föreg årtionde',
    nextDecade: 'Nästa årtionde',
    previousCentury: 'Föreg århundrade',
    nextCentury: 'Nästa århundrade',
};
export default locale;
