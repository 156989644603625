const locale = {
    locale: 'pl_PL',
    today: 'Dzisiaj',
    now: 'Teraz',
    backToToday: 'Ustaw dzisiaj',
    ok: 'Ok',
    clear: 'Wyczyść',
    month: 'Miesiąc',
    year: 'Rok',
    timeSelect: 'Ustaw czas',
    dateSelect: 'Ustaw datę',
    monthSelect: 'Wybierz miesiąc',
    yearSelect: 'Wybierz rok',
    decadeSelect: 'Wybierz dekadę',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Poprzedni miesiąc (PageUp)',
    nextMonth: 'Następny miesiąc (PageDown)',
    previousYear: 'Ostatni rok (Ctrl + left)',
    nextYear: 'Następny rok (Ctrl + right)',
    previousDecade: 'Ostatnia dekada',
    nextDecade: 'Następna dekada',
    previousCentury: 'Ostatni wiek',
    nextCentury: 'Następny wiek',
};
export default locale;
