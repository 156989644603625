export default {
    // Options.jsx
    items_per_page: '/ səhifə',
    jump_to: 'Get',
    jump_to_confirm: 'təsdiqlə',
    page: '',
    // Pagination.jsx
    prev_page: 'Əvvəlki Səhifə',
    next_page: 'Növbəti Səhifə',
    prev_5: 'Əvvəlki 5 Səhifə',
    next_5: 'Növbəti 5 Səhifə',
    prev_3: 'Əvvəlki 3 Səhifə',
    next_3: 'Növbəti 3 Səhifə',
};
