export default {
    // Options.jsx
    items_per_page: '/ side',
    jump_to: 'Gå til side',
    page: '',
    // Pagination.jsx
    prev_page: 'Forrige side',
    next_page: 'Neste side',
    prev_5: '5 forrige',
    next_5: '5 neste',
    prev_3: '3 forrige',
    next_3: '3 neste',
};
