const locale = {
    locale: 'gl_ES',
    today: 'Hoxe',
    now: 'Agora',
    backToToday: 'Voltar a hoxe',
    ok: 'Aceptar',
    clear: 'Limpar',
    month: 'Mes',
    year: 'Ano',
    timeSelect: 'Seleccionar hora',
    dateSelect: 'Seleccionar data',
    monthSelect: 'Elexir un mes',
    yearSelect: 'Elexir un año',
    decadeSelect: 'Elexir unha década',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Mes anterior (PageUp)',
    nextMonth: 'Mes seguinte (PageDown)',
    previousYear: 'Ano anterior (Control + left)',
    nextYear: 'Ano seguinte (Control + right)',
    previousDecade: 'Década anterior',
    nextDecade: 'Década seguinte',
    previousCentury: 'Século anterior',
    nextCentury: 'Século seguinte',
};
export default locale;
