export default {
    // Options.jsx
    items_per_page: '/ pagina',
    jump_to: 'vai a',
    jump_to_confirm: 'Conferma',
    page: '',
    // Pagination.jsx
    prev_page: 'Pagina precedente',
    next_page: 'Pagina successiva',
    prev_5: 'Precedente 5 pagine',
    next_5: 'Prossime 5 pagine',
    prev_3: 'Precedente 3 pagine',
    next_3: 'Prossime 3 pagine',
};
