const locale = {
    locale: 'ca_ES',
    today: 'Avui',
    now: 'Ara',
    backToToday: 'Tornar a avui',
    ok: 'Acceptar',
    clear: 'Netejar',
    month: 'Mes',
    year: 'Any',
    timeSelect: 'Seleccionar hora',
    dateSelect: 'Seleccionar data',
    monthSelect: 'Escollir un mes',
    yearSelect: 'Escollir un any',
    decadeSelect: 'Escollir una dècada',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Mes anterior (PageUp)',
    nextMonth: 'Mes següent (PageDown)',
    previousYear: 'Any anterior (Control + left)',
    nextYear: 'Mes següent (Control + right)',
    previousDecade: 'Dècada anterior',
    nextDecade: 'Dècada següent',
    previousCentury: 'Segle anterior',
    nextCentury: 'Segle següent',
};
export default locale;
