export default {
    // Options.jsx
    items_per_page: '/ გვერდი.',
    jump_to: 'გადასვლა',
    jump_to_confirm: 'დადასტურება',
    page: '',
    // Pagination.jsx
    prev_page: 'წინა გვერდი',
    next_page: 'შემდეგი გვერდი',
    prev_5: 'წინა 5 გვერდი',
    next_5: 'შემდეგი 5 გვერდი',
    prev_3: 'წინა 3 გვერდი',
    next_3: 'შემდეგი 3 გვერდი',
};
