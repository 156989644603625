export default {
    // Options.jsx
    items_per_page: '條/頁',
    jump_to: '跳至',
    jump_to_confirm: '確定',
    page: '頁',
    // Pagination.jsx
    prev_page: '上一頁',
    next_page: '下一頁',
    prev_5: '向前 5 頁',
    next_5: '向後 5 頁',
    prev_3: '向前 3 頁',
    next_3: '向後 3 頁',
};
