const locale = {
    locale: 'tr_TR',
    today: 'Bugün',
    now: 'Şimdi',
    backToToday: 'Bugüne Geri Dön',
    ok: 'tamam',
    clear: 'Temizle',
    month: 'Ay',
    year: 'Yıl',
    timeSelect: 'Zaman Seç',
    dateSelect: 'Tarih Seç',
    monthSelect: 'Ay Seç',
    yearSelect: 'Yıl Seç',
    decadeSelect: 'On Yıl Seç',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Önceki Ay (PageUp)',
    nextMonth: 'Sonraki Ay (PageDown)',
    previousYear: 'Önceki Yıl (Control + Sol)',
    nextYear: 'Sonraki Yıl (Control + Sağ)',
    previousDecade: 'Önceki On Yıl',
    nextDecade: 'Sonraki On Yıl',
    previousCentury: 'Önceki Yüzyıl',
    nextCentury: 'Sonraki Yüzyıl',
};
export default locale;
