export default {
    // Options.jsx
    items_per_page: '/ strana',
    jump_to: 'Choď na',
    jump_to_confirm: 'potvrdit',
    page: '',
    // Pagination.jsx
    prev_page: 'Predchádzajúca strana',
    next_page: 'Nasledujúca strana',
    prev_5: 'Predchádzajúcich 5 strán',
    next_5: 'Nasledujúcich 5 strán',
    prev_3: 'Predchádzajúce 3 strany',
    next_3: 'Nasledujúce 3 strany',
};
