export default {
    // Options.jsx
    items_per_page: '/ strani',
    jump_to: 'Idi na',
    page: '',
    // Pagination.jsx
    prev_page: 'Prethodna strana',
    next_page: 'Sledeća strana',
    prev_5: 'Prethodnih 5 Strana',
    next_5: 'Sledećih 5 Strana',
    prev_3: 'Prethodnih 3 Strane',
    next_3: 'Sledećih 3 Strane',
};
