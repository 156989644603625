const locale = {
    locale: 'ur_PK',
    today: 'آج',
    now: 'ابھی',
    backToToday: 'آج واپس',
    ok: 'ٹھیک ہے',
    clear: 'صاف',
    month: 'مہینہ',
    year: 'سال',
    timeSelect: 'وقت منتخب کریں',
    dateSelect: 'تاریخ منتخب کریں',
    weekSelect: 'ایک ہفتہ کا انتخاب کریں',
    monthSelect: 'ایک مہینہ کا انتخاب کریں',
    yearSelect: 'ایک سال کا انتخاب کریں',
    decadeSelect: 'ایک دہائی کا انتخاب کریں',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'پچھلے مہینے (PageUp)',
    nextMonth: 'اگلے مہینے (PageDown)',
    previousYear: 'گزشتہ سال (Control + left)',
    nextYear: 'اگلے سال (Control + right)',
    previousDecade: 'پچھلی دہائی',
    nextDecade: 'اگلی دہائی',
    previousCentury: 'پچھلی صدی',
    nextCentury: 'اگلی صدی',
};
export default locale;
