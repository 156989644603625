const locale = {
    locale: 'he_IL',
    today: 'היום',
    now: 'עכשיו',
    backToToday: 'חזור להיום',
    ok: 'אישור',
    clear: 'איפוס',
    month: 'חודש',
    year: 'שנה',
    timeSelect: 'בחר שעה',
    dateSelect: 'בחר תאריך',
    weekSelect: 'בחר שבוע',
    monthSelect: 'בחר חודש',
    yearSelect: 'בחר שנה',
    decadeSelect: 'בחר עשור',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'חודש קודם (PageUp)',
    nextMonth: 'חודש הבא (PageDown)',
    previousYear: 'שנה שעברה (Control + left)',
    nextYear: 'שנה הבאה (Control + right)',
    previousDecade: 'העשור הקודם',
    nextDecade: 'העשור הבא',
    previousCentury: 'המאה הקודמת',
    nextCentury: 'המאה הבאה',
};
export default locale;
