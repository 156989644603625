export default {
    // Options.jsx
    items_per_page: '/ ទំព័រ',
    jump_to: 'លោត​ទៅ',
    jump_to_confirm: 'បញ្ជាក់',
    page: 'ទំព័រ',
    // Pagination.jsx
    prev_page: 'ទំព័រ​មុន',
    next_page: 'ទំព័រ​​បន្ទាប់',
    prev_5: '៥ ទំព័រថយក្រោយ',
    next_5: '៥ ទំព័រទៅមុខ',
    prev_3: '៣ ទំព័រថយក្រោយ',
    next_3: '៣ ទំព័រទៅមុខ',
};
