const locale = {
    locale: 'ms_MY',
    today: 'Hari ini',
    now: 'Sekarang',
    backToToday: 'Kembali ke hari ini',
    ok: 'Ok',
    timeSelect: 'Pilih masa',
    dateSelect: 'Pilih tarikh',
    weekSelect: 'Pilih minggu',
    clear: 'Padam',
    month: 'Bulan',
    year: 'Tahun',
    previousMonth: 'Bulan lepas',
    nextMonth: 'Bulan depan',
    monthSelect: 'Pilih bulan',
    yearSelect: 'Pilih tahun',
    decadeSelect: 'Pilih dekad',
    yearFormat: 'YYYY',
    dayFormat: 'D',
    dateFormat: 'M/D/YYYY',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    previousYear: 'Tahun lepas (Ctrl+left)',
    nextYear: 'Tahun depan (Ctrl+right)',
    previousDecade: 'Dekad lepas',
    nextDecade: 'Dekad depan',
    previousCentury: 'Abad lepas',
    nextCentury: 'Abad depan',
};
export default locale;
