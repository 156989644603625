export default {
    // Options.jsx
    items_per_page: '/ страница',
    jump_to: 'Към',
    jump_to_confirm: 'потвърждавам',
    page: '',
    // Pagination.jsx
    prev_page: 'Предишна страница',
    next_page: 'Следваща страница',
    prev_5: 'Предишни 5 страници',
    next_5: 'Следващи 5 страници',
    prev_3: 'Предишни 3 страници',
    next_3: 'Следващи 3 страници',
};
