export default {
    // Options.jsx
    items_per_page: '/ ಪುಟ',
    jump_to: 'ಜಿಗಿತವನ್ನು',
    jump_to_confirm: 'ಖಚಿತಪಡಿಸಲು ಜಿಗಿತವನ್ನು',
    page: '',
    // Pagination.jsx
    prev_page: 'ಹಿಂದಿನ ಪುಟ',
    next_page: 'ಮುಂದಿನ ಪುಟ',
    prev_5: 'ಹಿಂದಿನ 5 ಪುಟಗಳು',
    next_5: 'ಮುಂದಿನ 5 ಪುಟಗಳು',
    prev_3: 'ಹಿಂದಿನ 3 ಪುಟಗಳು',
    next_3: 'ಮುಂದಿನ 3 ಪುಟಗಳು',
};
