const locale = {
    locale: 'fi_FI',
    today: 'Tänään',
    now: 'Nyt',
    backToToday: 'Tämä päivä',
    ok: 'Ok',
    clear: 'Tyhjennä',
    month: 'Kuukausi',
    year: 'Vuosi',
    timeSelect: 'Valise aika',
    dateSelect: 'Valitse päivä',
    monthSelect: 'Valitse kuukausi',
    yearSelect: 'Valitse vuosi',
    decadeSelect: 'Valitse vuosikymmen',
    yearFormat: 'YYYY',
    dateFormat: 'D.M.YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D.M.YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Edellinen kuukausi (PageUp)',
    nextMonth: 'Seuraava kuukausi (PageDown)',
    previousYear: 'Edellinen vuosi (Control + left)',
    nextYear: 'Seuraava vuosi (Control + right)',
    previousDecade: 'Edellinen vuosikymmen',
    nextDecade: 'Seuraava vuosikymmen',
    previousCentury: 'Edellinen vuosisata',
    nextCentury: 'Seuraava vuosisata',
};
export default locale;
