export default {
    // Options.jsx
    items_per_page: '/ الصفحة',
    jump_to: 'الذهاب إلى',
    jump_to_confirm: 'تأكيد',
    page: '',
    // Pagination.jsx
    prev_page: 'الصفحة السابقة',
    next_page: 'الصفحة التالية',
    prev_5: 'خمس صفحات سابقة',
    next_5: 'خمس صفحات تالية',
    prev_3: 'ثلاث صفحات سابقة',
    next_3: 'ثلاث صفحات تالية',
};
