export default {
    // Options.jsx
    items_per_page: '/ páxina',
    jump_to: 'Ir a',
    jump_to_confirm: 'confirmar',
    page: '',
    // Pagination.jsx
    prev_page: 'Páxina anterior',
    next_page: 'Páxina seguinte',
    prev_5: '5 páxinas previas',
    next_5: '5 páxinas seguintes',
    prev_3: '3 páxinas previas',
    next_3: '3 páxinas seguintes',
};
