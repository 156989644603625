const locale = {
    locale: 'uk_UA',
    today: 'Сьогодні',
    now: 'Зараз',
    backToToday: 'Поточна дата',
    ok: 'Ok',
    clear: 'Очистити',
    month: 'Місяць',
    year: 'Рік',
    timeSelect: 'Обрати час',
    dateSelect: 'Обрати дату',
    monthSelect: 'Обрати місяць',
    yearSelect: 'Обрати рік',
    decadeSelect: 'Обрати десятиріччя',
    yearFormat: 'YYYY',
    dateFormat: 'D-M-YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D-M-YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'Попередній місяць (PageUp)',
    nextMonth: 'Наступний місяць (PageDown)',
    previousYear: 'Попередній рік (Control + left)',
    nextYear: 'Наступний рік (Control + right)',
    previousDecade: 'Попереднє десятиріччя',
    nextDecade: 'Наступне десятиріччя',
    previousCentury: 'Попереднє століття',
    nextCentury: 'Наступне століття',
};
export default locale;
