const locale = {
    locale: 'ar_EG',
    today: 'اليوم',
    now: 'الأن',
    backToToday: 'العودة إلى اليوم',
    ok: 'تأكيد',
    clear: 'مسح',
    month: 'الشهر',
    year: 'السنة',
    timeSelect: 'اختيار الوقت',
    dateSelect: 'اختيار التاريخ',
    monthSelect: 'اختيار الشهر',
    yearSelect: 'اختيار السنة',
    decadeSelect: 'اختيار العقد',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'الشهر السابق (PageUp)',
    nextMonth: 'الشهر التالى(PageDown)',
    previousYear: 'العام السابق (Control + left)',
    nextYear: 'العام التالى (Control + right)',
    previousDecade: 'العقد السابق',
    nextDecade: 'العقد التالى',
    previousCentury: 'القرن السابق',
    nextCentury: 'القرن التالى',
};
export default locale;
