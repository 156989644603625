export default {
    // Options.jsx
    items_per_page: '/ পৃষ্ঠা',
    jump_to: 'যাও',
    jump_to_confirm: 'নিশ্চিত',
    page: '',
    // Pagination.jsx
    prev_page: 'আগের পৃষ্ঠা',
    next_page: 'পরের পৃষ্ঠা',
    prev_5: 'পূর্ববর্তী ৫ পৃষ্ঠা',
    next_5: 'পরবর্তী ৫ পৃষ্ঠা',
    prev_3: 'পূর্ববর্তী ৩ পৃষ্ঠা',
    next_3: 'পরবর্তী ৩ পৃষ্ঠা',
};
