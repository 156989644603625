export default {
    // Options.jsx
    items_per_page: '/ עמוד',
    jump_to: 'עבור אל',
    jump_to_confirm: 'אישור',
    page: '',
    // Pagination.jsx
    prev_page: 'העמוד הקודם',
    next_page: 'העמוד הבא',
    prev_5: '5 עמודים קודמים',
    next_5: '5 עמודים הבאים',
    prev_3: '3 עמודים קודמים',
    next_3: '3 עמודים הבאים',
};
